<template lang="pug">
  .max-fee
      span.title {{ $t('plan_settings.basic_settings.max') }}
      AppNumberInput.long(
        v-model="item.max_fee.$model"
        :invalid="item.max_fee.$error"
        :max="Number.POSITIVE_INFINITY"
      )
      span.yen {{ $t("plan_settings.basic_settings.currency") }}
</template>

<script>
  export default {
    components: {
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    props: {
      item: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .max-fee
    +fees-container

    .title
      padding-left: 8px
</style>
